.LocationsSelection {
	margin: 16px 0;
}

.LocationsSelection .LocationsSelectionCheckbox {
	border: 2px solid #cfcfcf;
	margin: 4px;
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.LocationsSelection .Location {
	margin-top: 4px;
	margin-bottom: 12px;
}
