.Customers .CustomerOrderCard {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 12px;
	padding: 8px 16px;
	border-radius: 8px;
	cursor: pointer;
	line-height: 2;
	background-color: #ececf4;
	white-space: nowrap;
	gap: 8px;
}

.CustomerOrderCard .OrderCardDetails {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.CustomerOrderCard .OrderCardDetails.StatusDate {
	text-align: right;
}

.CustomerOrderCard .OrderCardDetails.StatusDate .OrderCardDetailsStatus {
	font-weight: bold;
}
