.ManageBasicPresetsPopup {
	width: min(100%, 700px);
}

.ManageBasicPresetsPopup .PresetsContainer {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 0 30px;
}
