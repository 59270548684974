.LocationCard {
	cursor: pointer;
	margin: 24px 16px;
	padding: 16px 12px;
	width: 160px;
	background-color: grey;
	white-space: nowrap;
	border-radius: var(--smallRadius);
	text-align: center;
	background-color: #ececf5;
}
