.EmployeeAdd{
	padding: 10px 40px;
}

.EmployeeAdd .body {
	text-align: center;
}

.EmployeeAdd .body .emailInput,
.EmployeeAdd .body .AccessLevelDropDown,
.EmployeeAdd .LocationSelectionWrapper {
	margin-bottom: 40px;
}


.EmployeeAdd .action {
	padding: 12px 16px;
	display: flex;
	justify-content: center;
	width: 100%;
}

.EmployeeAdd .action .button {
	width: 100%;
}

.EmployeeAdd .LocationSelectionWrapper {
	text-align: start;
}
.EmployeeAdd .row {
	display: flex;
	align-items: center;
}

.EmployeeAdd .row div {
	flex: .5;
}

.EmployeeAdd .error {
	text-align: center;
	color: var(--negativeAccentColor)
}
