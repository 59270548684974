.FilterPopup .TypeHeader {
	font-size: 20px;
	font-weight: 500;
	padding-bottom: 8px;
}

.FilterPopup .TypeContainer {
	padding: 0 16px;
}

.FilterPopup .FilterContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.FilterPopup .StatusCheckbox {
	display: flex;
	padding: 6px 0;
	font-weight: 800;
	align-items: center;
	gap: 8px;
	font-size: 18px;
	white-space: nowrap;
}

.FilterPopup .action {
	display: flex;
	justify-content: center;
	padding-top: 30px;
}

.FilterPopup .DateTypeCheckBox {
	display: flex;
	gap: 8px;
	font-size: 18px;
	align-items: center;
}

.FilterPopup .DatePickerContainer {
	padding-left: 28px;
}

.FilterPopup .DatePicker {
	width: 150px;
	margin-right: 28px;
}

.FilterPopup .CalendarIcon {
	height: 100%;
}

.FilterPopup .DateFilter {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.FilterPopup .FromToDateInputs {
	display: flex;
	align-items: center;
	gap: 8px;
	padding-right: 8px;
}

.FilterPopup .FromToDateInputContainer {
	display: flex;
	gap: 8px;
	font-size: 18px;
}

.FilterPopup .CustomerFilterContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: -4px -8px;
}

.FilterPopup .HalfFilter {
	padding: 8px;
	width: 50%;
}
