.EditProductPopup{
	padding: 10px 40px;
}

.EditProductPopup .body {
	text-align: center;
}

.EditProductPopup .error {
	text-align: center;
	color: var(--negativeAccentColor)
}

.EditProductPopup .ProductEditForm {
	display: flex;
	gap: 20px;
}

.EditProductPopup .ProductEditForm .ActionContainer {
	padding: 8px;
	display: flex;
	justify-content: flex-end;
	font-size: 18px;
	font-weight: bold;
}

.EditProductPopup .ProductEditForm .ImageAndSkusContainer {
	width: 50%;
	max-width: 50%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}


.EditProductPopup .ProductEditForm .ChangeProductImage {
	background-color: #f1f9fe;
}

.EditProductPopup .ProductEditForm .ProductImagePreview {
	object-fit: contain;
	width: 80%;
	padding: 16px 0;
	height: 260px;
}

.EditProductPopup .ProductEditForm .PriceInput {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	gap: 8px;
	width: 100%;
}

.EditProductPopup .ProductEditForm .EditForm {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 50%;
	max-width: 50%;
	text-align: left;
}

.EditProductPopup .ProductEditForm .EditForm .subStory {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.EditProductPopup .DoneButtonContainer {
	padding: 40px 0;
	width: 100%;
	display: flex;
	justify-content: center;
}

.EditProductPopup .DoneButton {
	padding-left: 40px;
	padding-right: 40px;
}

.EditProductPopup .ProductEditForm .SizeAddForm {
	display: flex;
	gap: 8px;
}

.EditProductPopup .ProductEditForm .AvailableSizes {
	text-align: left;
}

.EditProductPopup .SaveButtonContainer {
	display: flex;
	justify-content: center;
	padding: 32px 0;
}

.EditProductPopup .ArchivedProductSkus {
	display: flex;
	flex-wrap: wrap;
}
