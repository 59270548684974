.Employees .title {
	text-align: center;
}

.Employees .EmployeeContaier .EmployeeCard:first-of-type {
	margin-top: 0px !important;
}

.Employees .EmployeeContaier .EmployeeCard:last-of-type {
	margin-bottom: 0px !important;
}

.Employees .EmployeeCard {
	border: 3px solid var(--borderColor);
	border-radius: var(--smallRadius);
	padding: 8px 16px;
	margin: 24px 0;
}
.Employees .EmployeeName {
	margin: 4px 0 !important;
}

.Employees .row {
	display: flex;
	align-items: center;
}

.Employees .row.header {
	justify-content: space-between;
}

.Employees .row div {
	flex: .5;
}

.Employees .EmployeeContaier {
	max-height: calc(100vh - 220px);
	overflow-y: auto;
	margin-top: 24px !important;
}
