
.OrderHistory {
	padding-bottom: 30px;
	line-height: 2.5;
}

.OrderHistory .OrderStatusCard {
	display: flex;
	justify-content: space-between;
	background-color: white;
	border-radius: 8px;
	padding: 8px 16px;
	font-size: 12px;
}

.OrderHistory .OrderStatusCard .OrderStatusCardTime {
	text-align: right;
}

.OrderHistory .OrderStatusCardContainer {
	gap: 16px;
	display: grid;
}
