.Orders .StatusTab.Tab {
	width: calc(var(--statusBarWidth) / 2);
	min-width: calc(var(--statusBarWidth) / 2);
	padding: 16px;
	background-color: #ececf5;
	color: #b7b7b7;
	text-transform: capitalize;
	display: table-cell;
	vertical-align: middle;
	border-radius: 8px;
	text-align: center;
	height: 100%;
}

.Orders .StatusTab.Tab.Active{
	background-color: #d1d1e3;
	color: #000;
	z-index: 3;
}
