.Orders .SideBar {
	width: var(--statusBarWidth);
	min-width: var(--statusBarWidth);
	overflow-y: auto;
	border: 15px solid #d1d1e3;
	background-color: #d1d1e3;
	gap: 15px;
	display: flex;
	flex-direction: column;
	scrollbar-width: none;
	max-height: calc(100vh - 250px);
}

.Orders .SideBar::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

.Orders .LoadMore {
	text-align: center;
	font-size: 12px;
	padding: 16px;
	cursor: pointer;
}
