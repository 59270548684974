.SelectLocation{
	display: flex;
	align-items: center;
	flex-direction: column;
}

.SelectLocation .CardsContainer {
	display: flex;
}

.SelectLocation .titleBar{
	position: relative;
	padding-bottom: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid var(--borderColor);
	text-align: center;
	width: 320px
}

.SelectLocation .titleBar .hemi{
	padding-bottom: 10px;
	font-size: 28px;
	white-space: nowrap;
}
