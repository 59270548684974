.ProductCardContainer .ProductCard {
	width: 100%;
	padding: 24px;
	background-color: white;
	background-color: #f1f9fe;
	border: 6px solid #f1f9fe;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow-x: hidden;
	cursor: pointer;
	height: 100%
}

.ProductCardContainer .ProductCard .ProductCardTitle {
	overflow-x: hidden;
	font-size: 30px;
	max-width: 100%;
}

.ProductCardContainer .ProductCard .PriceContainer, 
.ProductCardContainer .ProductCard .SkuCodeContainer {
	font-size: 18px;
	padding-top: 8px;
	justify-content: center;
	color: var(--lightTextColor);
}

.ProductCardContainer .ProductCard .PriceContainer .Price,
.ProductCardContainer .ProductCard .SkuCodeContainer .SkuCode {
	font-weight: bold;
}

.ProductCardContainer .ProductCard .ProductImage {
	max-width: 90%;
	padding: 20px 0;
	height: 260px;
}

.ProductCardContainer .InOutStockIndicator {
	width: 100%;
	text-align: center;
	color: var(--negativeAccentColor);
	font-weight: bold;
	margin-top: 16px;
}
