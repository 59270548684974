.Navbar{
	background-color: var(--navColor);
	color: #585858;
	padding: 22px 0;
	position: relative;
	position: sticky;
	top: 0;
	z-index: 100;
	margin: auto;
}

.Navbar .logo img{
	height: 50px;
}

.Navbar .controls{
	position: absolute;
	right: 0;
	font-weight: 600;
	font-size: 18px;
}

.Navbar .controls:last-child .NavbarItem{
	padding-right: 0px;
}

.Navbar .controls .link, .Navbar .controls .NavbarItem{
	padding: 0 15px;
	font-weight: 600;
	font-size: 18px;
}

.Navbar .controls .iconLink {
	display: flex;
	flex-direction: column;
	padding: 0 15px;
	font-weight: 600;
	text-align: center;
	align-items: center;
	gap: 5px;
}

.Navbar .iconContainer {
	position: relative;
}

.Navbar .controls .icon {
	font-size: 30px;
}

.Navbar .controls .iconText {
	font-size: 12px;
}

.Navbar .badge {
	font-size: 15px;
	position: absolute;
	background: var(--negativeAccentColor);
	color: white;
	height: 19px;
	width: 19px;
	border-radius: 50%;
	top: 0;
	right: 0;
	transform: translateX(7px) translateY(-3px);
}

.Navbar .controls .link:hover{
	color: var(--neutralAccentColor);
	cursor: pointer;
}

.Navbar .profileImageContainer {
	display: flex;
	flex-direction: column;
	font-size: 18px;
	gap: 3px;
}

.Navbar .profileImage {
	--profileImageSize: 35px;
	width: var(--profileImageSize);
	height: var(--profileImageSize);
}

.Navbar .userMenuButtonText {
	display: flex;
	gap: 3px;
}

.mobile .Navbar .logo,
.tablet .Navbar .logo{
	padding-left: 15px;
}

.mobile .Navbar .logo img,
.tablet .Navbar .logo img{
	height: 30px;
}

.Navbar .burger{
	font-size: 20pt;
}

.Navbar .toRight{
	position: absolute;
	right: 0;
	padding-right: 10px;
	font-size: 15pt;
}

.Navbar .toRight button{
	padding: 5px;
}

.Navbar .ActiveLocation {
	margin-left: 20px;
	font-weight: 600;
	font-size: 18px;
}
