.RemoveEmployeeButton {
	display: flex;
	justify-content: center;
	text-align: center;
	gap: 10px;
	font-size: 16px;
	margin: 16px 0;
}
.RemoveEmployeeButton div {
	color: var(--negativeAccentColor);
	font-weight: 600;
	cursor: pointer;
	width: min-content;
	white-space: nowrap;
}
