.Orders .TopBar {
	display: flex;
	flex-direction: row;
}

.Orders .SideBarSearchContainer {
	width: 100%;
	max-width: 100%;
	position: fixed;
	z-index: 2;
}

.Orders .SideBarContainer {
	position: absolute;
}

.Orders .SearchContainer {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	white-space: nowrap;
	gap: 24px;
	background-color: white;
	width: 100%;
	padding-left: var(--statusBarWidth);
	padding-bottom: 34px;
}

.Orders .SearchContainer .FilteredSearch {
	font-size: 18px;
	font-weight: 500;
}

.Orders .SearchBox {
	display: flex;
	flex-direction: column;
	color: var(--black);
	margin-left: 36px;
	width: 100%;
}

.Orders .SearchBox .SearchInput {
	height: 48px;
	padding-left: 28px;
	font-size: 22px;
}

.Orders .SearchBox .SearchIcon {
	color: #b7b7b7;
	position: absolute;
	margin: 8px;
	margin-top: 16px;
}

.Orders .SearchBox .SearchInput:focus {
	outline: none;
	caret-color: var(--fg);
}

.Orders .OrderScreen {
	position: relative;
	top: -7px;
	background-color: #fff;
	display: flex;
	height: calc(100vh - 190px);
}

.Orders .StatusTabsContainer {
	display: table-row;
}

.Orders .NewOrdersButton {
	background-color: green;
	display: flex;
	gap: 8px;
	font-weight: bold;
}

.Orders .NewOrdersButtonContainer {
	position: fixed;
	bottom: 64px;
	display: flex;
	justify-content: flex-end;
}
