.OrderDetails {
	padding: 16px;
	padding-right: 0px;
	width: 100%;
	max-width: 100%;
	overflow-x: auto;
	padding-top: 64px;
	flex-grow: 1;
	overflow-x: auto;
	padding-left: calc(var(--statusBarWidth) + 36px);
}

.OrderDetails .NextStep {
	font-size: 18px;
	padding-bottom: 32px;
}

.OrderDetails .NextStepTitle {
	padding-bottom: 15px;
}

.OrderDetails .Title {
	font-size: 36px;
	font-weight: bold;
	padding-bottom: 2px;
	white-space: nowrap;
	vertical-align: middle;
}

.OrderDetails .CurrentStatus {
	padding-bottom: 28px;
	font-size: 18px;
}

.OrderDetails .CurrentStatus > span {
	font-weight: bold;
}

.OrderDetails .RefundOrderButton {
	color: #D00000;
	cursor: pointer;
	font-size: 22px;
	font-weight: bold;
	text-align: right;
}

.OrderDetails .ActionDropdown {
	color: #fff;
	padding: 12px 16px;
	margin-left: 8px;
	cursor: pointer;
	width: calc(100% - 8px);
	font-size: 22px;
}

.ActionDropdownItem {
	width: 218px;
	padding: 4px 8px;
	text-align: center;
}
.OrderDetails .ActionDropdown .CaretIcon {
	color: #fff;
	vertical-align: center;
}
.OrderDetails .ActionDropdown .DropdownTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	white-space: nowrap;
	font-weight: bold;
}

.OrderDetails .Status {
	color: #FFF;
	padding: 16px 64px;
	text-align: center;
	margin-top: 8px;
	cursor: pointer;
	text-transform: capitalize;
}

.OrderDetails .BodyContainer {
	display: flex;
	justify-content: space-between;
	overflow-y: auto;
}

.OrderDetails .Body  {
	padding: 10px 0;
}

.OrderDetails .Body .Label {
	color: #979797;
}

.OrderDetails .Body .Value {
	padding-left: 8px;
	font-weight: bold;
}
.OrderDetails .OrderItemsTitle {
	color: #979797;
	padding-bottom: 8px;
}

.OrderDetails .Header { 
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.OrderDetails .StatusDropdown {
	padding-top: 8px;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	width: 100%;
}

.OrderDetails .StatusDropdown .menuButtonContainer,
.OrderDetails .StatusDropdown .menuButtonContainer .MenuButton {
	width: 100%;
}

.OrderDetails .StatusDropdown .StatusTitle {
	font-size: 22px;
}
