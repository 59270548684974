
.Comments {
	padding-bottom: 30px;
	line-height: 2.5;
}


.Comments .CommentCard {
	background-color: white;
	border-radius: 8px;
	padding: 8px 16px;
	font-size: 12px;
}

.Comments .CommentCard .Comment {
	color: var(--lightTextColor);
}

.Comments .CommentCard .CommentDate {
	text-align: right;
}

.Comments .CommentCardContainer {
	gap: 16px;
	display: grid;
}

.Comments .CommentCardContainer .AddCommentInput {
	background-color: white;
}

.Comments .CommentCardContainer .AddCommentButton {
	width: max-content;
}
