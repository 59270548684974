.Customers .CustomerCard {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 12px;
	padding: 8px 16px;
	border-radius: 8px;
	cursor: pointer;
	line-height: 2;
	background-color: #ececf4;
}

.Customers .CustomerCard.Active {
	border: 4px solid #99999b;
}

