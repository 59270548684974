.CollapsibleCard {
	padding: 4px 24px;
	width: 100%;
	background-color: #ececf5;
}

.CollapsibleCard .CollapsibleCardHeader {
	padding: 8px 0px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	cursor: pointer;
	font-size: 22px;
	line-height: 1.8;
}

.CollapsibleCard .CollapsibleCardBodyWrapper {
	overflow: hidden;
	transition: max-height 2s cubic-bezier(0, 1.2, 0, 1.2);
}

.CollapsibleCard .CollapsibleCardBody {
	padding: 16px 0px;
}

.CollapsibleCard .CollapsibleCardSeparator {
	display: none;
}
.CollapsibleCard.Expanded .CollapsibleCardSeparator {
	display: block;
	margin: 0px -24px;
	border-bottom: 1px solid var(--borderColor);
}

.CollapsibleCard .CollapsibleCardHeader .CaretIcon {
	padding-left: 12px;
	vertical-align: center;
}

.CollapsibleCard .CollapsibleCardHeader .CaretIcon {
	padding-left: 12px;
}

.CollapsibleCard.Expanded .CollapsibleCardHeader .CaretIcon .fa-caret-down {
	transform: rotate(180deg);
	-webkit-transform: rotate(180deg);
}
