.ConfirmSkuDeletePopup .body {
	text-align: center;
}

.ConfirmSkuDeletePopup .action button {
	padding-top: 12px;
	margin: 0 6px;
}

.ConfirmSkuDeletePopup .action {
	padding: 36px 0 24px 0;
}

.ConfirmSkuDeletePopup .BoldName {
	font-weight: bold;
}
