.ConfirmStatusPopup{
	padding: 10px 40px;
}

.ConfirmStatusPopup label{
	padding-left: 5px;
	padding-bottom: 7px;
	display: block;
}

.ConfirmStatusPopup .body {
	text-align: center;
}

.ConfirmStatusPopup .action button {
	padding-top: 12px;
	margin: 0 6px;
}

.ConfirmStatusPopup .action {
	padding: 36px 0 24px 0;
}
