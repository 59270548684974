.AddProductPopup {
	padding: 10px 40px;
}

.AddProductPopup .body {
	text-align: center;
}

.AddProductPopup .error {
	text-align: center;
	color: var(--negativeAccentColor)
}

.AddProductPopup .ProductAddForm {
	display: flex;
	gap: 20px;
}

.AddProductPopup .ProductAddForm .ActionContainer {
	padding: 8px;
	display: flex;
	justify-content: flex-end;
	font-size: 18px;
	font-weight: bold;
}

.AddProductPopup .ProductAddForm .ChangeProductImage {
	width: 50%;
	max-width: 50%;
	background-color: #f1f9fe;
	
}

.AddProductPopup .ProductAddForm .ProductImagePreview {
	object-fit: contain;
	width: 80%;
	padding: 16px 0;
	height: 260px;
}

.AddProductPopup .ProductAddForm .PriceInput {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	gap: 8px;
	width: 100%;
}

.AddProductPopup .ProductAddForm .AddForm {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 50%;
	max-width: 50%;
}

.AddProductPopup .ProductAddForm .AddForm .subStory {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.AddProductPopup .DoneButtonContainer {
	padding: 40px 0;
	width: 100%;
	display: flex;
	justify-content: center;
}

.AddProductPopup .DoneButton {
	padding-left: 40px;
	padding-right: 40px;
}
