.ConfirmDeletePopup .body {
	text-align: center;
}

.ConfirmDeletePopup .action button {
	padding-top: 12px;
	margin: 0 6px;
}

.ConfirmDeletePopup .action {
	padding: 36px 0 24px 0;
}

.ConfirmDeletePopup .ProductName {
	font-weight: bold;
}
