.SignInPopup{
	padding: 10px 40px;
}

.SignInPopup label{
	padding-left: 5px;
	padding-bottom: 7px;
	display: block;
}

.SignInPopup .authIcon{
	padding-right: 20px;
}

.SignInPopup .authIcon.facebook{
	padding-right: 10px;
}
