.OrderContent .ContentItem {
	padding-bottom: 30px;
	line-height: 2.5;
}

.OrderContent .ContentItem .CollapsibleCard {
	background-color: #f1f9fe !important;
}

.OrderContent .ContentItem .CollapsibleCard .AdvancedNotes {
	color: var(--lightTextColor);
}
