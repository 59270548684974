.ConfirmActionPopup{
	padding: 10px 40px;
}

.ConfirmActionPopup label{
	padding-left: 5px;
	padding-bottom: 7px;
	display: block;
}

.ConfirmActionPopup .body {
	text-align: center;
	text-transform: capitalize;
}

.ConfirmActionPopup .action {
	padding: 36px 0 24px 0;
}

.ConfirmActionPopup .action button {
	margin: 0 6px;
}
