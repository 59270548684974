.DeleteProductsPopup{
	padding: 10px 40px;
}

.DeleteProductsPopup .body {
	text-align: center;
}

.DeleteProductsPopup .action {
	padding: 12px 16px;
	display: flex;
	justify-content: center;
	width: 100%;
}

.DeleteProductsPopup .ProductContainer {
	margin-top: 24px !important;
	display: flex;
	flex-direction: column;
	gap: 32px;
}

.DeleteProductsPopup .ProductContainer .RemoveFromListButton {
	margin-top: 16px;
}

.DeleteProductsPopup .error {
	text-align: center;
	color: var(--negativeAccentColor)
}

.DeleteProductsPopup .NoDeletedProductsMessage{
	color: #9898A8;
}
