.AccessLevelDropDown {
	display: flex;
	align-items: center;
	white-space: nowrap;
	width: 100%;
}

.AccessLevelDropDown select {
	margin-left: 8px;
	width: 100%;
}

