.App, .root{
	width: 100vw;
	width: 100%;
	color: var(--mainTextColor);
}

.App .portalDestination:empty{
	display: none;
}

.App .appContents.appBackground{
	height: 100vh;
	width: 100%;
	overflow: hidden;
	position: absolute;
	top:0;
	left: 0;
	z-index: -1;
}

.App .env {
	position: fixed;
	text-transform: uppercase;
	font-size: 2.5rem;
	top: 15vh;
	right: 0;
	color: rgb(31, 224, 31);
	background: rgb(236, 236, 236);
	width: 11ch;
	padding: 0.75rem;
	z-index: 21;
}
