.SavedCards {
	padding-bottom: 30px;
	line-height: 2.5;
}

.SavedCards .SavedCardsContainer {
	display: flex;
	flex-direction: column;
	gap: 15px;
}

.SavedCards .CardContainer {
	display: flex;
	flex-direction: column;
	gap: 2px;
	padding: 8px 16px;
	border-radius: 8px;
	background-color: #ececf4;
}
