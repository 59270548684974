.ConfirmEmployeeRemove{
	padding: 10px 40px;
}

.ConfirmEmployeeRemove .body {
	text-align: center;
}

.ConfirmEmployeeRemove .actions {
	padding: 12px 0;
	display: flex;
	justify-content: space-between;
}

.ConfirmEmployeeRemove .actions button {
	flex: .48;
}
