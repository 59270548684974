.Products .title {
	text-align: center;
	white-space: nowrap;
}

.Products .ProductContainer {
	margin-top: 24px !important;
	display: flex;
	flex-wrap: wrap;
	margin: 0 -20px;
}

@media only screen and (max-width: 1000px) {
	.Products .ProductContainer .ProductCardContainer {
		width: 50% !important;
		max-width: 50% !important;
	}
}

.Products .ProductContainer .ProductCardContainer {
	width: 25%;
	max-width: 25%;
	padding: 20px;
}

.Products .ActionContainer {
	padding: 64px 0;
	display: flex;
	align-items: flex-end;
	font-size: 22px;
	font-weight: bold;
	gap: 32px;
	flex-direction: column;
}

.Products .header {
	flex-wrap: nowrap;
}

.Products .header .SearchBox {
	display: flex;
	flex-direction: column;
	color: var(--black);
	margin-left: 36px;
	width: 100%;
}

.Products .header .SearchBox .SearchInput {
	height: 48px;
	padding-left: 28px;
	font-size: 22px;
}

.Products .header .SearchBox .SearchIcon {
	color: #b7b7b7;
	position: absolute;
	margin: 8px;
	margin-top: 16px;
}

.Products .header .SearchBox .SearchInput:focus {
	outline: none;
	caret-color: var(--fg);
}
