.ConfirmRefundPopup {
	padding: 10px 40px;
}

.ConfirmRefundPopup label{
	padding-left: 5px;
	padding-bottom: 7px;
	display: block;
}

.ConfirmRefundPopup .body {
	text-align: center;
	text-transform: capitalize;
}

.ConfirmRefundPopup .action {
	padding: 36px 0 24px 0;
}

.ConfirmRefundPopup .RefundAmount {
	color: #D00000;
	font-weight: 600;
}

.ConfirmRefundPopup .action button {
	padding-top: 12px;
	margin: 0 6px;
}

.ConfirmRefundPopup .error {
	text-align: center;
	color: var(--negativeAccentColor)
}

