.PresetCard {
	display: flex;
	gap: 20px;
}
.PresetCard .ImageSpecsContainer {
	display: flex;
	background-color: #f1f9fe;
	border-radius: var(--smallRadius);
	padding: 20px;
	width: 100%;
	gap: 20px;
}
.PresetCard .PresetImage {
	width: 100px;
	height: 100px;
}
.PresetCard .Preset {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
}
.PresetCard .SpecsContainer {
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.PresetCard .SpecContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.PresetCard .SpecName {
	color: var(--lightTextColor);
}
.PresetCard .SpecValue {
	display: flex;
	font-weight: bold;
	align-items: center;
	gap: 2px;
}
.PresetCard .SpecInput {
	width: 75px;
}
.PresetCard .ActionButton {
	white-space: nowrap;
	font-weight: bold;
	min-width: 115px;
}
.PresetCard .PresetName {
	font-size: 20px;
	font-weight: bold;
}
