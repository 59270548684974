.AppliedFilters {
	width: 350px;
	min-width: 350px;
	padding: 16px;
	background-color: #d1d1e3;
}

.AppliedFilters .AppliedFiltersTitle {
	display: flex;
	font-size: 24px;
	padding-bottom: 4px;
	align-items: center;
	justify-content: space-between;
}

.AppliedFilters .DatePicker {
	width: 130px;
	margin-right: 28px;
}

.AppliedFilters .Range {
	display: flex;
	gap: 8px;
}

.AppliedFilters .StatusCardContainer {
	display: flex;
	flex-direction: column;
	gap: 14px;
	width: min-content;
	padding-top: 20px;
}

.AppliedFilters .StatusCard {
	display: flex;
	align-items: center;
	background-color: rgb(236, 236, 245);
	padding: 10px;
	border-radius: var(--smallRadius);
	font-weight: 600;
	white-space: nowrap;
	gap: 12px;
	justify-content: space-between;
}

.AppliedFilters .exitIcon {
	cursor: pointer;
}

.AppliedFilters .ChangeFiltersButton {
	margin-top: 20px;
}

.AppliedFilters .DateFilters {
	display: flex;
	gap: 4px;
	flex-direction: column;
	padding-top: 20px;
}

.AppliedFilters .CustomerFilterContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 -4px;
}

.AppliedFilters .HalfFilter {
	padding: 4px;
	padding-top: 20px;
	width: 50%;
}
